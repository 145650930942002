import {lazy} from "react"
import {Switch} from "react-router-dom"

import {FTLErrorPage} from "ftl-uikit"
import {PrivateRoute} from "ftl-core"

//Restaurants
const RestaurantsList = lazy(() => import("./pages/Restaurant/List"))
const RestaurantsDetailPage = lazy(() => import("./pages/Restaurant/Detail"))

//Countries
const CountriesList = lazy(() => import("./pages/Country/List"))
const CountriesDetailPage = lazy(() => import("./pages/Country/Detail"))

//Cities
const CitiesList = lazy(() => import("./pages/City/List"))
const CitiesDetailPage = lazy(() => import("./pages/City/Detail"))


//Delivery zones
const DeliveryZonesCitiesList = lazy(() => import("./pages/DeliveryZones/DeliveryZonesCitiesList"))
const UploadMapForDeliveryZones = lazy(() => import("./pages/DeliveryZones/UploadMapForDeliveryZones"))
const DeliveryZonesDetail = lazy(() => import("./pages/DeliveryZones/Detail"))

//Orders
const OrdersList = lazy(() => import("./pages/Order/List"))
const OrdersWIthExternalProblems = lazy(() => import("./pages/OrdersWIthExternalProblems/List"))
const OrdersDetailPage = lazy(() => import("./pages/Order/Detail"))
const OrdersWIthExternalProblemsDetail = lazy(() => import("./pages/OrdersWIthExternalProblems/Detail"))
const WorkloadPage = lazy(() => import("./pages/Order/WorkloadPage"))

//Customers
const CustomersList = lazy(() => import("./pages/Customer/List"))
const ArchivedCustomersList = lazy(() => import("./pages/Customer/DeletedCustomersList"))
const CustomersDetailPage = lazy(() => import("./pages/Customer/Detail"))

//FeedbackMessage
const FeedbackMessageList = lazy(() => import("./pages/FeedbackMessage/List"))

//Dishes
const DishesList = lazy(() => import("./pages/Dish/List"))
const DishesDetailPage = lazy(() => import("./pages/Dish/Detail"))


//Constructors
const ConstructorsList = lazy(() => import("./pages/Constructor/List"))
const ConstructorsDetailPage = lazy(() => import("./pages/Constructor/Detail"))

//Price Categories
const PriceCategoriesList = lazy(() => import("./pages/PriceCategory/List"))
const PriceCategoriesDetail = lazy(() => import("./pages/PriceCategory/Detail"))

//Prices
const PricesList = lazy(() => import("./pages/Price/List"))
const PricesDetailPage = lazy(() => import("./pages/Price/Detail"))

//Catalogs
const CatalogsList = lazy(() => import("./pages/Catalog/List"))
const CatalogsDetailPage = lazy(() => import("./pages/Catalog/Detail"))

//Categories
const CategoriesList = lazy(() => import("./pages/Category/List"))
const CategoriesDetailPage = lazy(() => import("./pages/Category/Detail"))

//Banners
const BannersList = lazy(() => import("./pages/Banner/List"))
const BannersDetailPage = lazy(() => import("./pages/Banner/Detail"))

//BannerGroups
const BannerGroupsList = lazy(() => import("./pages/BannerGroup/List"))
const BannerGroupsDetailPage = lazy(() => import("./pages/BannerGroup/Detail"))

//Stories
const StoriesList = lazy(() => import("./pages/Stories/List"))
const StoriesDetailPage = lazy(() => import("./pages/Stories/Detail"))


//Stories-Groups
const StoriesGroupsList = lazy(() => import("./pages/StoriesGroup/List"))
const StoriesGroupsDetailPage = lazy(() => import("./pages/StoriesGroup/Detail"))

//Stories-Groups
const FeedbackReasonsList = lazy(() => import("./pages/FeedbackReason/List"))
const FeedbackReasonDetailPage = lazy(() => import("./pages/FeedbackReason/Detail"))

const NomenclatureList = lazy(() => import("./pages/Nomenclature/List"))
const NomenclatureDetailPage = lazy(() => import("./pages/Nomenclature/Detail"))

const PromotionList = lazy(() => import("./pages/Promotion/List"))
const PromotionDetailPage = lazy(() => import("./pages/Promotion/Detail"))

const GiftRepurchasesList = lazy(() => import("./pages/Promotion/GiftRepurchases/List"))
const GiftRepurchasesDetailPage = lazy(() => import("./pages/Promotion/GiftRepurchases/Detail"))

const ComplectationList = lazy(() => import("./pages/Complectation/List"))
const ComplectationDetailPage = lazy(() => import("./pages/Complectation/Detail"))

const IIkoConfigDetailPage = lazy(() => import("./pages/OrderCommentTemplate/Detail"))

const BirthdayModerationPage = lazy(() => import("./pages/BirthdayModeration/List"))

const SmsList = lazy(() => import("./pages/Sms/List"))

const NotificationsList = lazy(() => import("./pages/Notification/List"))
const NotificationsDetailPage = lazy(() => import("./pages/Notification/Detail"))
const CreateNotificationMailingPage = lazy(() => import("./pages/Notification/CreateNotificationMailing"))

//verisions-management
const VersionsManagementPage = lazy(() => import("./pages/VersionsManagement/VersionsManagementPage"))

//stop-city
const OrderStopCityPage = lazy(() => import("./pages/OrderStop/OrderStopCityList"))

//personal offers
const PersonalOffersList = lazy(() => import("./pages/PersonalOffer/List"))
const PersonalOffersDetailPage = lazy(() => import("./pages/PersonalOffer/Detail"))

//payment type filter
const PaymentTypeFiltersList = lazy(() => import("./pages/PaymentTypeFilter/List"))
const PaymentTypeFiltersDetailPage = lazy(() => import("./pages/PaymentTypeFilter/Detail"))

const FeaturesInfoManagement = lazy(() => import("./pages/FeaturesInfo/FeaturesInfoManagement"))

//release notes
const ReleaseNotesList = lazy(() => import("./pages/ReleaseNote/List"))
const ReleaseNotesDetailPage = lazy(() => import("./pages/ReleaseNote/Detail"))
const ReleaseNotesViewPage = lazy(() => import("./pages/ReleaseNote/ReleaseNoteViewPage"))


//personal offers
const LegalsList = lazy(() => import("./pages/Legal/List"))
const LegalsDetailPage = lazy(() => import("./pages/Legal/Detail"))


const NewYearWishesPage = lazy(() => import("./pages/NewYearWishes/NewYearWishesPage"))

//Map
const MapPage = lazy(() => import("./pages/Map/MapPage"))


//SettingsPage
const SettingsPage = lazy(() => import("./pages/SettingsPage/SettingsPage"))

const OrderSettingsPage = lazy(() => import("./pages/OrderSettings/OrderSettingsPage"))

//ProductSync
const ProductSyncPage = lazy(() => import("./pages/ProductSync/ProductSyncPage"))

//Test
const Test = lazy(() => import("./pages/Test/Test"))

export const Routes = () => {
  return (
    <Switch>
      <PrivateRoute exact path="/restaurants">
        <RestaurantsList />
      </PrivateRoute>
      <PrivateRoute exact path="/restaurants/new">
        <RestaurantsDetailPage pageType="new" />
      </PrivateRoute>
      <PrivateRoute exact path="/restaurants/:id">
        <RestaurantsDetailPage pageType="edit" />
      </PrivateRoute>

      <PrivateRoute exact path="/countries">
        <CountriesList />
      </PrivateRoute>
      <PrivateRoute exact path="/countries/new">
        <CountriesDetailPage pageType={"new"} />
      </PrivateRoute>
      <PrivateRoute exact path="/countries/:id">
        <CountriesDetailPage pageType={"edit"} />
      </PrivateRoute>

      <PrivateRoute exact path="/cities">
        <CitiesList />
      </PrivateRoute>
      <PrivateRoute exact path="/cities/new">
        <CitiesDetailPage pageType={"new"} />
      </PrivateRoute>
      <PrivateRoute exact path="/cities/:id">
        <CitiesDetailPage pageType={"edit"} />
      </PrivateRoute>

      <PrivateRoute exact path="/cities-delivery-zones">
        <DeliveryZonesCitiesList />
      </PrivateRoute>
      <PrivateRoute exact path={"/cities/:id/delivery-zones"}>
        <UploadMapForDeliveryZones />
      </PrivateRoute>
      <PrivateRoute exact path={"/cities/:cityId/delivery-zones/:id"}>
        <DeliveryZonesDetail pageType={"edit"} />
      </PrivateRoute>

      <PrivateRoute exact path="/orders">
        <OrdersList />
      </PrivateRoute>
      <PrivateRoute exact path="/with-external-problem">
        <OrdersWIthExternalProblems />
      </PrivateRoute>
      <PrivateRoute exact path="/orders/workload">
        <WorkloadPage />
      </PrivateRoute>
      <PrivateRoute exact path="/with-external-problem/:id">
        <OrdersWIthExternalProblemsDetail pageType={"edit"} />
      </PrivateRoute>
        <PrivateRoute exact path="/orders/:id">
        <OrdersDetailPage pageType={"edit"} />
      </PrivateRoute>

      <PrivateRoute exact path="/customers">
        <CustomersList />
      </PrivateRoute>
      <PrivateRoute exact path="/archived-customers">
        <ArchivedCustomersList />
      </PrivateRoute>
      <PrivateRoute exact path="/customers/:id">
        <CustomersDetailPage pageType={"edit"} />
      </PrivateRoute>

      <PrivateRoute exact path="/feedback-messages">
        <FeedbackMessageList />
      </PrivateRoute>

      <PrivateRoute exact path="/dishes">
        <DishesList />
      </PrivateRoute>
      <PrivateRoute exact path="/dishes/new">
        <DishesDetailPage pageType={"new"} productType={"DISH"} />
      </PrivateRoute>
      <PrivateRoute exact path="/dishes/:id">
        <DishesDetailPage pageType={"edit"} productType={"DISH"} />
      </PrivateRoute>

      <PrivateRoute exact path="/price-categories">
        <PriceCategoriesList />
      </PrivateRoute>
      <PrivateRoute exact path="/price-categories/new">
        <PriceCategoriesDetail pageType={"new"} />
      </PrivateRoute>
      <PrivateRoute exact path="/price-categories/:id">
        <PriceCategoriesDetail pageType={"edit"} />
      </PrivateRoute>

      <PrivateRoute exact path="/prices">
        <PricesList />
      </PrivateRoute>
      <PrivateRoute exact path="/prices/new">
        <PricesDetailPage pageType={"new"} />
      </PrivateRoute>
      <PrivateRoute exact path="/prices/:id">
        <PricesDetailPage pageType={"edit"} />
      </PrivateRoute>

      <PrivateRoute exact path="/catalogs">
        <CatalogsList />
      </PrivateRoute>
      <PrivateRoute exact path="/catalogs/new">
        <CatalogsDetailPage pageType={"new"} />
      </PrivateRoute>
      <PrivateRoute exact path="/catalogs/:id">
        <CatalogsDetailPage pageType={"edit"} />
      </PrivateRoute>

      <PrivateRoute exact path="/banners">
        <BannersList />
      </PrivateRoute>
      <PrivateRoute exact path="/banners/new">
        <BannersDetailPage pageType={"new"} />
      </PrivateRoute>
      <PrivateRoute exact path="/banners/:id">
        <BannersDetailPage pageType={"edit"} />
      </PrivateRoute>

      <PrivateRoute exact path="/banner-groups">
        <BannerGroupsList />
      </PrivateRoute>
      <PrivateRoute exact path="/banner-groups/new">
        <BannerGroupsDetailPage pageType={"new"} />
      </PrivateRoute>
      <PrivateRoute exact path="/banner-groups/:id">
        <BannerGroupsDetailPage pageType={"edit"} />
      </PrivateRoute>

      <PrivateRoute exact path="/stories">
        <StoriesList />
      </PrivateRoute>
      <PrivateRoute exact path="/stories/new">
        <StoriesDetailPage pageType={"new"} />
      </PrivateRoute>
      <PrivateRoute exact path="/stories/:id">
        <StoriesDetailPage pageType={"edit"} />
      </PrivateRoute>

      <PrivateRoute exact path="/stories-groups">
        <StoriesGroupsList />
      </PrivateRoute>
      <PrivateRoute exact path="/stories-groups/new">
        <StoriesGroupsDetailPage pageType={"new"} />
      </PrivateRoute>
      <PrivateRoute exact path="/stories-groups/:id">
        <StoriesGroupsDetailPage pageType={"edit"} />
      </PrivateRoute>

      <PrivateRoute exact path="/categories">
        <CategoriesList />
      </PrivateRoute>
      <PrivateRoute exact path="/categories/new">
        <CategoriesDetailPage pageType={"new"} />
      </PrivateRoute>
      <PrivateRoute exact path="/categories/:id">
        <CategoriesDetailPage pageType={"edit"} />
      </PrivateRoute>

      <PrivateRoute exact path="/feedback-reasons">
        <FeedbackReasonsList />
      </PrivateRoute>
      <PrivateRoute exact path="/feedback-reasons/new">
        <FeedbackReasonDetailPage pageType={"new"} />
      </PrivateRoute>
      <PrivateRoute exact path="/feedback-reasons/:id">
        <FeedbackReasonDetailPage pageType={"edit"} />
      </PrivateRoute>

      <PrivateRoute exact path="/nomenclatures">
        <NomenclatureList />
      </PrivateRoute>
      <PrivateRoute exact path="/nomenclatures/new">
        <NomenclatureDetailPage pageType={"new"} />
      </PrivateRoute>
      <PrivateRoute exact path="/nomenclatures/:id">
        <NomenclatureDetailPage pageType={"edit"} />
      </PrivateRoute>

      <PrivateRoute exact path="/promotions">
        <PromotionList />
      </PrivateRoute>
      <PrivateRoute exact path="/promotions/new">
        <PromotionDetailPage pageType={"new"} />
      </PrivateRoute>
      <PrivateRoute exact path="/promotions/:id">
        <PromotionDetailPage pageType={"edit"} />
      </PrivateRoute>

      <PrivateRoute exact path="/gift-repurchases">
        <GiftRepurchasesList />
      </PrivateRoute>
      <PrivateRoute exact path="/gift-repurchases/new">
        <GiftRepurchasesDetailPage pageType={"new"} />
      </PrivateRoute>
      <PrivateRoute exact path="/gift-repurchases/:id">
        <GiftRepurchasesDetailPage pageType={"edit"} />
      </PrivateRoute>

      <PrivateRoute exact path="/complectations">
        <ComplectationList />
      </PrivateRoute>
      <PrivateRoute exact path="/complectations/new">
        <ComplectationDetailPage pageType={"new"} />
      </PrivateRoute>
      <PrivateRoute exact path="/complectations/:id">
        <ComplectationDetailPage pageType={"edit"} />
      </PrivateRoute>

      <PrivateRoute exact path="/constructors">
        {/*<SoonPage text={'Конструкторы'} />*/}
        <ConstructorsList />
      </PrivateRoute>
      <PrivateRoute exact path="/constructors/new">
        <ConstructorsDetailPage pageType={"new"} productType={"CONSTRUCTOR"} />
      </PrivateRoute>
      <PrivateRoute exact path="/constructors/:id">
        <ConstructorsDetailPage pageType={"edit"} productType={"CONSTRUCTOR"} />
      </PrivateRoute>

      <PrivateRoute exact path="/order-comment-template">
        <IIkoConfigDetailPage />
      </PrivateRoute>

      <PrivateRoute exact path="/birthday-moderation">
        <BirthdayModerationPage />
      </PrivateRoute>

      <PrivateRoute exact path="/sms">
        <SmsList />
      </PrivateRoute>

      <PrivateRoute exact path="/notifications">
        <NotificationsList />
      </PrivateRoute>
      <PrivateRoute exact path="/notifications/:id">
        <NotificationsDetailPage pageType={"edit"} />
      </PrivateRoute>
      <PrivateRoute exact path="/create-notifications-mailing">
        <CreateNotificationMailingPage />
      </PrivateRoute>

      <PrivateRoute exact path={"/city-stops"}>
        <OrderStopCityPage />
      </PrivateRoute>

      <PrivateRoute exact path="/versions-management">
        <VersionsManagementPage />
      </PrivateRoute>

      <PrivateRoute exact path="/personal-offers">
        <PersonalOffersList />
      </PrivateRoute>
      <PrivateRoute exact path="/personal-offers/new">
        <PersonalOffersDetailPage pageType={"new"} />
      </PrivateRoute>
      <PrivateRoute exact path="/personal-offers/:id">
        <PersonalOffersDetailPage pageType={"edit"} />
      </PrivateRoute>

      <PrivateRoute exact path="/payment-type-filters">
        <PaymentTypeFiltersList />
      </PrivateRoute>
      <PrivateRoute exact path="/payment-type-filters/new">
        <PaymentTypeFiltersDetailPage pageType={"new"} />
      </PrivateRoute>
      <PrivateRoute exact path="/payment-type-filters/:id">
        <PaymentTypeFiltersDetailPage pageType={"edit"} />
      </PrivateRoute>

      <PrivateRoute exact path={"/features-info-management"}>
        <FeaturesInfoManagement />
      </PrivateRoute>

      <PrivateRoute exact path="/release-notes">
        <ReleaseNotesList />
      </PrivateRoute>
      <PrivateRoute exact path="/release-notes/new">
        <ReleaseNotesDetailPage pageType={"new"} />
      </PrivateRoute>
      <PrivateRoute exact path="/release-notes/:id">
        <ReleaseNotesDetailPage pageType={"edit"} />
      </PrivateRoute>
      <PrivateRoute exact path="/release-notes/:id/view">
        <ReleaseNotesViewPage />
      </PrivateRoute>

      <PrivateRoute exact path="/legals">
        <LegalsList />
      </PrivateRoute>
      <PrivateRoute exact path="/legals/new">
        <LegalsDetailPage pageType={"new"} />
      </PrivateRoute>
      <PrivateRoute exact path="/legals/:id">
        <LegalsDetailPage  pageType={"edit"} />
      </PrivateRoute>


      <PrivateRoute exact path="/special-date-promotion">
        <NewYearWishesPage />
      </PrivateRoute>


      <PrivateRoute exact path={"/map"}>
        <MapPage />
      </PrivateRoute>

      <PrivateRoute exact path="/settings">
        <SettingsPage />
      </PrivateRoute>

      <PrivateRoute path={"/order-settings"} exact>
        <OrderSettingsPage />
      </PrivateRoute>

      <PrivateRoute exact path={"/product-sync"}>
        <ProductSyncPage />
      </PrivateRoute>

      {process.env.REACT_APP_ENV === "dev" && (
        <PrivateRoute exact path="/test">
          <Test />
        </PrivateRoute>
      )}

      <PrivateRoute exact path={["*", "/404"]}>
        <FTLErrorPage
          title="Страницы не существует"
          message="Наверное, вы перешли по неправильной ссылке."
        />
      </PrivateRoute>
    </Switch>
  )
}
